<template>
  <div class="docking">
    <el-button size="mini" @click="add"><i class="el-icon-plus"></i> 新增</el-button>
    <el-button size="mini" @click="active">
      <i class="el-icon-setting"></i> 激活</el-button
    >
    <el-button size="mini" @click="remove">
      <i class="el-icon-setting"></i> 删除</el-button
    >
    <el-table
      :data="tableData"
      border
      highlight-current-row
      @current-change="handleChange"
    >
      <el-table-column prop="index" label="编号" width="80"> </el-table-column>
      <el-table-column prop="name" label="姓名" width="150"> </el-table-column>
      <el-table-column prop="isActive" label="是否激活" width="100">
      </el-table-column>
      <el-table-column prop="time" label="创建时间"> </el-table-column>
    </el-table>
  </div>
  <el-dialog title="扫描二维码添加" v-model="erweimaModel">
    <qrcode-vue :value="erweimaValue" :size="200"> </qrcode-vue>
  </el-dialog>
</template>

<script>
import { reactive, ref } from "vue";
import { Baoxiu, postActive, postRemove } from "../http/docking";
import { ElMessage } from "element-plus";
import QrcodeVue from "qrcode.vue";
// @ is an alias to /src
export default {
  name: "Docking",
  components: {
    QrcodeVue,
  },
  setup() {
    const erweimaModel = ref(false)
    const erweimaValue = ref('')
    const current = reactive({
      id: "",
      isActive: "",
      name: "",
    });
    const tableData = reactive([]);
    //点击报修人

    function handleChange(currentRow) {
      (current.id = currentRow.id), (current.isActive = currentRow.isActive);
      current.name = currentRow.name;
      console.log("====");
    }
    //获取报修人员
    function getBaoxiu() {
      Baoxiu()
        .then((res) => {
          tableData.length = 0;
          let data = res.data;
          let result = data.rows;
          for (let i = 1; i <= result.length; i++) {
            let refData = {
              index: i,
              name: result[i - 1].user_name,
              isActive: Boolean(result[i - 1].status) ? "是" : "否",
              time: result[i - 1].create_time,
              id: result[i - 1].id,
            };
            tableData.push(refData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    //新增
     function add(){
       erweimaModel.value =  true
       erweimaValue.value = 'https://rhkpwy.com/UserInfo/WXuzzbinding?way=1'
     }
    //激活
    function active() {
      if (current.id) {
        if (current.isActive == "是") {
          ElMessage.warning("该项已经激活");
        } else if (current.isActive == "否") {
          postActive(current.id).then((res) => {
            let data = res.data;
            let state = data.state;
            if (state == 0) {
              ElMessage.success("激活成功");
              getBaoxiu();
            } else if (state == 1) {
              ElMessage.error(data.message);
            }
          });
        }
      } else {
        ElMessage.warning("请选择一项");
      }
    }

    //删除

    function remove() {
      if (current.id) {
        postRemove(current.id)
          .then((res) => {
            let data = res.data;
            let state = data.state;
            if (state == 0) {
              ElMessage.success("删除成功");
              getBaoxiu();
            }
          })
          .catch((err) => {
            ElMessage.error(err);
          });
      } else {
        ElMessage.warning("请选择一项");
      }
    }
    getBaoxiu();
    return { tableData, handleChange, active, current, remove,erweimaModel,add,erweimaValue };
  },
};
</script>

<style scoped>
/deep/.el-table__body tr.current-row > td {
  background-color: #ffe48d;
}
</style>
